<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">


                 <h4>
                   <router-link to="/resource">
                   <i class="fa fa-arrow-left" style="width: 40px"></i>
                 </router-link> Manage attachment</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ <router-link to="/resource">Resource</router-link> \ Attachment
                </div>
              </div>

            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr class="px-3">
                  <th>Title</th>
                  <th>Type</th>
                  <th>Downloadable</th>
                    <th class="pr-3 text-center">Action</th>
                </tr>

                </thead>
                <tbody>
                <tr v-for="(attachment, i) of attachments" :key="i">
                  <td>
                    <a :href="attachment.type=='link'?attachment.file_path:'#'"
                       :target="attachment.type=='link'?'_blank':''" class="mr-2"> {{ attachment.title }}</a>
                    &nbsp;
                    <i class="fas fa-circle"
                       :class="attachment.is_active?'dot-active':'dot-warning'"></i>
                  </td>
                  <td>
                    {{ capitalizeText(attachment.type) }}
                  </td>
                  <td class="">

                    <span>
                        <v-icon small
                                :color="attachment.is_downloadable ? '#0abb87' : 'red'"
                                :class="attachment.is_downloadable ? 'fas fa-check' : 'fas fa-ban'">
                        </v-icon>
                      </span>
                  </td>
                  <td class="pr-0 text-center">
                    <b-dropdown
                                size="sm"
                                variant="link"
                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                no-caret
                                right
                                no-flip
                    >
                      <template v-slot:button-content>
                        <slot>
                            <span>
                              <i class="flaticon-more-1"></i>
                            </span>
                        </slot>
                      </template>
                      <div class="navi navi-hover">
                        <b-dropdown-text tag="div" class="navi-item" v-if="attachment.is_downloadable">
                          <a
                              href="#"
                              class="navi-link"
                              @click="downloadAttachment(attachment)"
                          >
                              <span class="navi-icon">
                                <i class="fas fa-download"></i>
                              </span>
                            <span class="navi-text"> Download attachment</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item">
                          <a
                              href="#"
                              class="navi-link"
                              @click="viewAttachment(attachment)"
                          >
                              <span class="navi-icon">
                                <i class="fas fa-eye"></i>
                              </span>
                            <span class="navi-text"> View attachment</span>
                          </a>
                        </b-dropdown-text>
                      </div>
                    </b-dropdown>


                  </td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import AttachmentService from "@/core/services/library/resource/AttachmentService";

const attachmentService = new AttachmentService();

export default {
  name: "Index",
  components: {},
  data() {
    return {
      search: {},
      attachments: [],
      dialog: false,
      page: 1,
      total: null,
      perPage: null,
      active_statuses: [
        {title: 'Active', value: "active"},
        {title: 'In Active', value: "in_active"}
      ],
    }
  }, computed: {
    resource_id() {
      return this.$route.params.id;
    }
  },
  mounted() {
    this.getAttachments()
  },
  methods: {
    getAttachments() {
      this.dialog = false
      attachmentService.byResource(this.resource_id).then(response => {
        this.attachments = response.data
      })
    },
    deleteAttachment(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            attachmentService.delete(id).then(response => {
              this.getAttachments();
            })
          }
        }
      });
    },
    deleteRack(shelfId, id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            attachmentService.delete(shelfId, id).then(response => {
              this.$snotify.success("Attachment deleted successfully")
              this.getAttachments();
            })
          }
        }
      });
    }, addAttachment() {
      this.dialog = true
    },
    editAttachment(resource) {
      this.dialog = true
      this.$refs['attachment'].editAttachment(this.resource_id)
    },
    closeDialog() {
      this.dialog = false
      this.getAttachments();
    },
    manageAttachment(resource) {
      this.$refs['attachment'].manageAttachment(resource)
    },
    downloadAttachment(attachment) {
      attachmentService.getDownloadUrl(attachment.id).then(response => {
        window.open(response.data, "_blank")
      })
    },
    viewAttachment(attachment){
      this.$router.push({
        name: 'library-resource-attachment-view', params: {
          resource: attachment.library_resource_id,
          attachment: attachment.id
        }
      })
    },
    capitalizeText(text) {
      return text
          .toLowerCase()
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
    }
  }
}
</script>

<style scoped>
</style>